export const TillNumbersColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "tillNumber", headerName: "Till Number", flex: 1 },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "insightType", headerName: "Insight Type", flex: 1 },
];
export const PayBillsColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "paybill", headerName: "Paybill", flex: 1 },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "insightType", headerName: "Insight Type", flex: 1 },
];
export const TransactionTypeColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "description", headerName: "Description", flex: 1 },
];
export const InsightTypeColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "description", headerName: "Description", flex: 1 },
];
export const InsightKeywordsColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "keyword", headerName: "Keyword", flex: 1 },
  { field: "insightType", headerName: "Insight Type", flex: 1 },
];
export const TransactionKeywordsColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "keyWord", headerName: "Keyword", flex: 1 },
  { field: "regexpression", headerName: "Regexpression", flex: 1 },
  { field: "transactionTypeId", headerName: "Transaction Type ID", flex: 1 },
];
export const TitleFSColumns = [
  { field: "id", headerName: "ID", flex: 1 },

  { field: "title", headerName: "Title", flex: 1 },
];
export const allStatementsColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },

  { field: "customerName", headerName: "Customer Name", flex: 2.5 },
  { field: "customerMobile", headerName: "Customer Mobile", flex: 1 },
  { field: "emailAddress", headerName: "Email Address", flex: 2 },
  { field: "statementPeriod", headerName: "Statement Period", flex: 2 },
  { field: "requestDate", headerName: "Request Date", flex: 2 },
];
export const bankStatementsColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },

  // { field: "firstName", headerName: "First Name", flex: 1 },
  // { field: "lastName", headerName: "Last Name", flex: 1 },
  // { field: "email", headerName: "Email", flex: 1 },
  { field: "customerName", headerName: "Customer Name", flex: 1 },
  { field: "bankName", headerName: "Bank", flex: 1 },
  { field: "type", headerName: "Type", flex: 1 },
];
