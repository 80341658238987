import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import swal from "sweetalert";
import { useMutation } from "@apollo/client";
import * as yup from "yup";
import { Formik } from "formik";
import Header from "../../../../components/Header";
import {
  CREATEBANKSTATEMENTSTYPES,
  CREATETRANSACTIONKEYWORD,
  UPDATEBANKTRANSACTIONKEYWORD,
} from "../../../../data/SetupManagementData";
import {
  allBanks,
  allBanksTransactionTypesView,
} from "../../../../data/Axios/queries";
import { graphqlQuery } from "../../../../data/Axios/DynamicService";
import { setupManagementUrl } from "../../../../config";

const base_url = setupManagementUrl.uri;

const BankTransactionKeywordForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const [bankData, setBankData] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [filteredTransactionTypes, setFilteredTransactionTypes] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");

  useEffect(() => {
    fetchBankData();
  }, []);

  const [createBankTransactionKeyword] = useMutation(CREATETRANSACTIONKEYWORD, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal(
        "Success!",
        "Bank Transaction Key Word has been created successfully",
        "success"
      );
    },
    onError: () => {
      swal(
        "Error!",
        "Unable to create Bank Transaction Key Word, try again later",
        "error"
      );
    },
  });

  const [updateBankTransactionKeyword] = useMutation(
    UPDATEBANKTRANSACTIONKEYWORD,
    {
      onCompleted: () => {
        props.onClose();
        props.onAction();
        swal(
          "Success!",
          "Bank Transaction Key Word has been updated successfully",
          "success"
        );
      },
      onError: () => {
        swal(
          "Error!",
          "Unable to update Bank Transaction Key Word, try again later",
          "error"
        );
      },
    }
  );

  const fetchBankData = async () => {
    try {
      const [banksResponse, transactionsResponse] = await Promise.all([
        graphqlQuery(allBanks, base_url),
        graphqlQuery(allBanksTransactionTypesView, base_url),
      ]);

      if (banksResponse) {
        setBankData(banksResponse || []);
      }

      if (transactionsResponse) {
        setTransactionType(transactionsResponse || []);
        const Transaction = await graphqlQuery(
          allBanksTransactionTypesView,
          base_url
        );
        if (Transaction) {
          setTransactionType(Transaction);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const initialValues = {
    bankId: props.isEditing ? props.data.bankId : "",
    transactionTypeId: props.isEditing ? props.data.transactionTypeId : "",
    keyWord: props.isEditing ? props.data.keyWord : "",
    regex: props.isEditing ? props.data.regex : "",
  };

  const validationSchema = yup.object().shape({
    bankId: yup.string().required("Bank name is required"),
    transactionTypeId: yup.string().required("Transaction type is required"),
    keyWord: yup.string().required("Keyword is required"),
    regex: yup.string(),
  });

  const handleBankChange = (e) => {
    const bankId = e.target.value;
    const selectedBank = bankData.find((bank) => bank.id === bankId);

    if (selectedBank) {
      const bankName = selectedBank.bankName;
      setSelectedBank(bankName);

      const filtered = transactionType.filter(
        (transaction) => transaction.bankName === bankName
      );
      setFilteredTransactionTypes(filtered);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { bankId, transactionTypeId, keyWord, regex } = values;

      if (props.isEditing) {
        await updateBankTransactionKeyword({
          variables: {
            id: props.data.id,
            bankId,
            transactionTypeId,
            keyWord,
            regex,
            updatedBy: userId,
          },
        });
      } else {
        await createBankTransactionKeyword({
          variables: {
            bankId,
            transactionTypeId,
            keyWord,
            regex,
            createdBy: userId,
          },
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
      swal("Error!", `${error.message}`, "error");
    }
    setSubmitting(false);
  };

  const BankOptions = bankData.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.bankName,
  }));

  const TransactionOptions = filteredTransactionTypes.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.type,
  }));

  return (
    <Box m="20px">
      <Header
        title={
          props.isEditing
            ? "Edit Bank Transaction Key Word"
            : "Create Bank Transaction Key Word"
        }
        subtitle={
          props.isEditing
            ? "Edit an Existing Bank Transaction Key Word"
            : "Create a New Bank Transaction Key Word"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <FormControl
                fullWidth
                variant="filled"
                error={touched.bankId && !!errors.bankId}
              >
                <InputLabel id="bank-select-label">
                  Choose the bank *
                </InputLabel>
                <Select
                  labelId="bank-select-label"
                  value={values.bankId}
                  name="bankId"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleBankChange(e);
                  }}
                >
                  {BankOptions.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.bankId && errors.bankId && (
                  <p style={{ color: "red" }}>{errors.bankId}</p>
                )}
              </FormControl>

              <FormControl
                fullWidth
                variant="filled"
                error={touched.transactionTypeId && !!errors.transactionTypeId}
              >
                <InputLabel id="transaction-select-label">
                  Transaction Type *
                </InputLabel>
                <Select
                  labelId="transaction-select-label"
                  value={values.transactionTypeId}
                  name="transactionTypeId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {TransactionOptions.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.transactionTypeId && errors.transactionTypeId && (
                  <p style={{ color: "red" }}>{errors.transactionTypeId}</p>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Keyword *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.keyWord}
                name="keyWord"
                error={touched.keyWord && !!errors.keyWord}
                helperText={touched.keyWord && errors.keyWord}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Regex"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.regex}
                name="regex"
                error={touched.regex && !!errors.regex}
                helperText={touched.regex && errors.regex}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default BankTransactionKeywordForm;
